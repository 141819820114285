import React from 'react'
import { NavLink } from "react-router-dom";
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import favicon from '../../Assets/Images/JaceLogo.png'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button } from './UI/Button';
import { useAuth } from '../../Context/AuthProvider';

const NavigationBar = () => {

    const history = useHistory();

    const { authData, logout } = useAuth();

    const navigation = [
        { name: 'Home', route: '/' },
        { name: 'Ambulances', route: '/drivers' },
        { name: 'Tracking', route: '/drivers' },
        { name: 'Staff', route: '/staff' },
    ];


    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth' });
    };

    const handleTabClick = (route) => {
        history.push(route);
    };

    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleNavigation = (link) => {
        history.push(link);
    };

    const resetSessionStorage = () => {
        sessionStorage.removeItem('activeTab');
        sessionStorage.removeItem('currentPage');
    };

    return (
        <>
            <Popover>
                <div className="relative py-2 px-4 sm:px-6 lg:px-8" id='home'>
                    <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start mx-auto" aria-label="Global">
                        <div className="flex flex-shrink-0 items-center lg:flex-grow-0">
                            <NavLink to={'/'} onClick={resetSessionStorage}>
                                <span className="sr-only">Jace Medical</span>
                                <div>
                                    <img
                                        className="h-auto w-28"
                                        src={favicon}
                                        alt=""
                                    />
                                </div>
                            </NavLink>
                        </div>
                        <div className="hidden md:flex flex-grow items-center justify-end space-x-8">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.route}
                                    className="font-medium text-gray-500 hover:text-gray-900"
                                    onClick={() => handleTabClick(item.route)}
                                >
                                    {item.name}
                                </a>
                            ))}
                            {authData.token ? (
                                <div className="relative ml-4 z-20">
                                    <button
                                        onClick={handleDropdownToggle}
                                        className="bg-primary h-auto text-white px-10 py-2 rounded font-display font-medium hover:bg-primary/90 transition duration-300 relative"
                                    >
                                        <span className="text-base flex flex-col font-semibold">
                                            {authData.name}
                                            {authData.role === "0" && (
                                                <span className="mt-1 font-semibold bg-[#DCAE62] text-white rounded-md px-2 py-1 text-xs inline-block"> {/* Changed background color to bg-yellow-400 */}
                                                    STAFF
                                                </span>
                                            )}
                                            {authData.role === "1" && (
                                                <span className="mt-1 bg-[#DCAE62] text-white rounded-md px-2 py-1 text-xs">
                                                    ADMIN
                                                </span>
                                            )}
                                        </span>
                                    </button>
                                    <div className={`absolute ${isDropdownOpen ? 'block' : 'hidden'} bg-white shadow-md rounded mt-2`}>
                                        {(authData.role === '1') && (
                                            <a
                                                onClick={() => {
                                                    handleDropdownToggle()
                                                    handleNavigation('/settings')
                                                }}
                                                className="cursor-pointer block px-4 py-2 text-gray-800 hover:bg-gray-200"
                                            >
                                                Admin Settings
                                            </a>
                                        )}
                                        {(authData.role === '1') && (
                                            <a
                                                onClick={() => {
                                                    handleDropdownToggle()
                                                    handleNavigation('/admin/invoicing')
                                                }}
                                                className="cursor-pointer block px-4 py-2 text-gray-800 hover:bg-gray-200"
                                            >
                                                Invoicing
                                            </a>
                                        )}
                                        {(authData.role === '1') && (
                                            <a
                                                onClick={() => {
                                                    handleDropdownToggle()
                                                    handleNavigation('/admin/reporting')
                                                }}
                                                className="cursor-pointer block px-4 py-2 text-gray-800 hover:bg-gray-200"
                                            >
                                                Reporting
                                            </a>
                                        )}
                                        <a
                                            onClick={() => {
                                                logout()
                                            }}
                                            className="cursor-pointer block px-4 py-2 text-gray-800 hover:bg-gray-200"
                                        >
                                            Log out
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    onClick={() => handleNavigation('/login')}
                                    className="bg-[#DCAE62] h-auto text-white px-10 py-3 rounded font-display font-medium hover:bg-[#a993e6] transition duration-300"
                                >
                                    Login
                                </button>
                            )}
                        </div>
                        <div className="md:hidden -mr-2 flex items-center">
                            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#56bcbe]">
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                        </div>
                    </nav>
                </div>

                <Transition
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute inset-x-0 top-0 z-20 origin-top-right transform p-2 transition md:hidden"
                    >
                        <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                            <div className="flex items-center justify-between px-5 pt-4">
                                <div>
                                    <img
                                        className="h-auto w-36"
                                        src={favicon}
                                        alt=""
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#56bcbe]">
                                        <span className="sr-only">Close main menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="space-y-1 px-2 pt-2 pb-3">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                                        onClick={() => handleTabClick(item.route)}
                                    >
                                        {item.name}
                                    </a>
                                ))}

                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </>
    )
}

export default NavigationBar
